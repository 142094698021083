import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import "../styles/global.scss";

import Seo from "../components/seo";

const NotFoundPage = () => {
  const errordata = useStaticQuery(graphql`
    query newQuery {
      sanityInfo {
        errorcode {
          code
        }
        errortexta
        _rawErrortextb
      }
    }
  `);

  const errorcode = errordata.sanityInfo.errorcode.code;
  const errortexta = errordata.sanityInfo.errortexta;
  const errortextb = errordata.sanityInfo._rawErrortextb;

  const components = {
    marks: {
      link: ({ children, value }) => {
        return (
          <a href={value.href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        );
      },
    },
  };

  return (
    <>
      <Seo title="Home" />
      <main className="padding main-error">
        <p className="parens">{errortexta}</p>
        <pre className="pre-ascii">
          <code>{errorcode}</code>
        </pre>
        <div>
          <PortableText value={errortextb} components={components} />
        </div>
      </main>
    </>
    // <Layout>
    //   <Seo title="404: Not found" />
    //   <h1>404: Not Found</h1>
    //   <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    // </Layout>
  );
};

export default NotFoundPage;
